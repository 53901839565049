import React, { useState, useEffect } from 'react';

import './Videolist.css';
import Video from './Video';

const API_URL = process.env.REACT_APP_API_URL;

const Videolist = ({videos}) => {
    // console.log('Videos in Videolist:', videos);
    const [currentPage, setCurrentPage] = useState(1);
    const [fetchedVideos, setFetchedVideos] = useState([]); // Renamed state for fetched videos


    useEffect(() => {
        // Only fetch videos if searchResults is not provided
        if (!videos || videos.length === 0) {
            fetchVideos(currentPage);
        }
    }, [currentPage, videos]); // Depend on currentPage and searchResults

    const fetchVideos = async (page) => {
        const url = `${API_URL}/listAllVideos?limit=12&offset=${(page - 1) * 10}`;
        // console.log(url);
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Fetch error: ${response.statusText}`);
            }
            const data = await response.json();
            setFetchedVideos(data); // Update fetchedVideos state with fetched data
        } catch (error) {
            console.error('Error fetching videos:', error);
        }
    };
    // Determine which videos to display - prop videos or fetched videos
    const displayVideos = videos && videos.length > 0 ? videos : fetchedVideos;

  return (
    <div>
        <div className='video-list-container'>
            <div className='video-list'>
                {displayVideos.map(video => (
                            <Video key={video.id} id={video.id} />
                        ))}
            </div>
            <div className="pagination">
            <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous Page
            </button>
            <button onClick={() => setCurrentPage(currentPage + 1)}>Next Page</button>
            </div>
        </div>
    </div>
  );
};

export default Videolist;