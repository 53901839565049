import React, { useState } from "react";
import "./CommentForm.css";

import { auth } from "../../firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import Avatar from "@mui/material/Avatar";

import { createComment } from "./comment.api";

export default function CommentForm({ videoId, onCommentAdd }) {
  const [comment, setComment] = useState("");
  const [user, loading, error] = useAuthState(auth);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Here you can add the code to send the comment to the server

    try {
      const newComment = await createComment(comment, videoId, user);
      setComment(newComment);
    } catch (error) {
      console.error("Error handling like:", error);
    }

    setComment("");
  };

  const handleCancel = () => {
    setComment("");
  };

  return (
    <div className="comment-section">
      {user && (
        <Avatar src={user.photoURL} alt={user.displayName || user.email} />
      )}

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Add a comment..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />

        <div className="comment-form-buttons">
          <button type="submit">Comment</button>
          <button type="button" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
