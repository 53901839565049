import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Avatar from "@mui/material/Avatar";
import "./Comment.css";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { useFetchLikes } from "./hooks/useFetchLikes";
import { auth } from "../../firebase";
import { useFetchDislikes } from "./hooks/useFetchDislikes";
import { useFetchCommentUserData } from "./hooks/useFetchCommentUser";
import { getDaysAgo } from "../../globals";
import { postLikeComment, postDislikeComment } from "./comment.api";

export default function Comment({
  id: commentId,
  content,
  commentUser,
  created_at,
}) {
  const [user, loading, error] = useAuthState(auth);
  const [commentUserData] = useFetchCommentUserData(null);

  const [likes, setLikes] = useFetchLikes(commentId);
  const [dislikes, setDislikes] = useFetchDislikes(commentId); // New state for dislikes

  const handleLike = async () => {
    try {
      const likes = await postLikeComment(user, commentId);
      setLikes(likes);
    } catch (error) {
      console.error("Error handling like:", error);
    }
  };

  const handleDislike = async () => {
    try {
      const dislikes = await postDislikeComment(user, commentId);
      setDislikes(dislikes);
    } catch (error) {
      console.error("Error handling dislike:", error);
    }
  };

  return (
    <div className="comment-section">
      <div>
        {commentUserData && (
          <Avatar
            src={commentUserData.photo_url}
            alt={commentUserData.display_name || commentUserData.email}
          />
        )}
      </div>

      <div>
        {/* <p>{commentUserData.display_name}</p> */}
        <p>{getDaysAgo(created_at)}</p>
        <p>{content}</p>
      </div>

      <div className="comment-buttons">
        <button
          type="button"
          className="engagement-button"
          onClick={handleLike}
        >
          <ThumbUpIcon />
          {likes > 0 && <span>{likes}</span>}
        </button>
        <button
          type="button"
          className="engagement-button"
          onClick={handleDislike}
        >
          <ThumbDownIcon />
          {dislikes > 0 && <span>{dislikes}</span>}
        </button>
      </div>
    </div>
  );
}
