import React, { useEffect, useState } from "react";
import "./Librarylist.css";
import Video from "../Video";
import { useUser } from "../UserContext";
import { updateVideoTitle, fetchVideos } from "./LibraryList.api";
import Searchbar from "../Search/Searchbar";
import Search from "@mui/icons-material/Search";

const Librarylist = () => {
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const currentUser = useUser();
  const [editingVideoId, setEditingVideoId] = useState(null); // Track which video is being edited
  const [editTitle, setEditTitle] = useState(""); // Editable title

  useEffect(() => {
    const getVideos = async () => {
      try {
        const fetchedVideos = await fetchVideos(currentUser.uid, currentPage);
        setVideos(fetchedVideos);
        console.log("Fetched videos:", fetchedVideos);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };
  
    getVideos();
  }, [currentPage, currentUser.uid])


  
  const handleEdit = (video) => {
    setEditingVideoId(video.id);
    setEditTitle(video.title);
  };

  const submitEdit = async (videoId) => {
    try {
      const updatedVideos = updateVideoTitle(editTitle, videoId, videos);
      setVideos(updatedVideos);
      setEditingVideoId(null);
    } catch (error) {
      console.error("Error updating video:", error);
    }
  };
  return (
    <div>
      <Searchbar></Searchbar>
      <div className="library-list-container">
        <p>Posted Videos</p>
        <div className="library-list">
          {videos.map((video) => (
            <div className="library-list-item" key={video.id}>
              {editingVideoId === video.id ? (
                <div>
                  <Video className="library-asset" id={video.id} />

                  <textarea
                    className="save-textarea"
                    type="text"
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                  />
                  <button onClick={() => submitEdit(video.id)}>Save</button>
                </div>
              ) : (
                <div className="library-list-video-details">
                  <Video className="library-asset" id={video.id} />
                  <button
                    className="edit-button"
                    onClick={() => handleEdit(video)}
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="pagination">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous Page
          </button>
          <button onClick={() => setCurrentPage(currentPage + 1)}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default Librarylist;
