import { API_URL } from "../../globals";

export const postLikeComment = async (user, commentId) => {
  try {
    const response = await fetch(`${API_URL}/engagements`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.uid,
        commentId,
        liked: true,
        disliked: false,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return data.likes;
    } else {
      console.error("Error liking comment");
    }
  } catch (err) {
    console.error("Error liking comment", err);
  }
};

export const postDislikeComment = async (user, commentId) => {
  try {
    const response = await fetch(`${API_URL}/engagements`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.uid,
        commentId,
        liked: false,
        disliked: true,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return data.dislikes; // Update dislikes state
    } else {
      console.error("Error disliking comment");
    }
  } catch (err) {
    console.error("Error disliking comment", err);
  }
};

export const createComment = async (comment, videoId, user) => {
  try {
    const response = await fetch(`${API_URL}/comments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        content: comment,
        video_id: videoId,
        user_id: user.id, // Assuming user's id in your database is same as Firebase uid
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchDislikes = async (commentId) => {
  try {
    const res = await fetch(`${API_URL}/comments/${commentId}/dislikes`);
    const data = await res.json();
    return data.dislikes;
  } catch (err) {
    console.error("Error:", err);
  }
};

export const fetchLikes = async (commentId) => {
  try {
    const res = await fetch(`${API_URL}/comments/${commentId}/likes`);
    const data = await res.json();
    return data.likes;
  } catch (err) {
    console.error("Error:", err);
  }
};
