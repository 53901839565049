import React, { useState, useEffect } from "react";
import Searchbar from "./Searchbar";
import Videolist from "../Videolist";
import { getVideoSearchResults } from "./Search.api";

const ParentVideoSearchResult = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!searchTerm) {
        setSearchResults([]);
        return;
      }

      try {
        const searchTermResults = await getVideoSearchResults(searchTerm);
        console.log("searchTermResults", searchTermResults);
        setSearchResults(searchTermResults);
      } catch (error) {
        console.error("Error fetching search results", error);
      }
    };

    fetchSearchResults();
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <Searchbar onSearchChange={handleSearchChange} />
      <Videolist videos={searchResults} />
    </div>
  );
};

export default ParentVideoSearchResult;
