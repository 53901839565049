const API_URL = process.env.REACT_APP_API_URL;

export const fetchVideos = async (userId, page) => {
  try {


    const response = await fetch(
      `${API_URL}/listAllLibraryVideos?user_id=${userId}&limit=3&offset=${
        (page - 1) * 10
      }`
    );

    if (!response.ok) {
      throw new Error(`Fetch error: ${response.statusText}`);
    }
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};

export const updateVideoTitle = async (newtitle, videoId, videos) => {
  try {
    const response = await fetch(`${API_URL}/videos/${videoId}/update_title`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        title: newtitle,
      }),
    });

    if (!response.ok) {
      throw new Error(`Update error: ${response.statusText}`);
    }

    const fetchResponse = await fetch(`${API_URL}/videos/${videoId}`);

    if (!fetchResponse.ok) {
      throw new Error(`Fetch error: ${fetchResponse.statusText}`);
    }
    const updatedVideo = await fetchResponse.json();
    const newVideos = videos.map((video) =>
      video.id === videoId ? updatedVideo : video
    );

    return newVideos;
  } catch (error) {
    console.error("Error updating video:", error);
  }
};
