import "./App.css";
import Searchbar from "./Components/Search/Searchbar.js";
import Video from "./Components/Video";
import SideMenu from "./Components/SideMenu";
import VideoUpload from "./Components/VideoUpload";
import Videolist from "./Components/Videolist";
import FilterBar from "./Components/FilterBar";
import VideoDetails from "./Components/VideoDetails";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Librarylist from "./Components/LibraryList/Librarylist";
import Walletlist from "./Components/WalletList.js";
import TradeModal from "./Components/TradeModal";
import ParentVideoSearchResult from "./Components/Search/ParentVideoSearchResult.js";
import { toast, ToastContainer } from "react-toastify"; // Import ToastContainer here
import { Analytics } from "@vercel/analytics/react"

// App.js
import { UserProvider } from "./Components/UserContext";
import RecentViewHistory from "./Components/RecentViewHistory.js";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Analytics />
      <UserProvider>
        <Router>
          <header className="App-header">
            <div className="main">
              <SideMenu />
            </div>

            <Routes>
              <Route path="/" element={<ParentVideoSearchResult />} />
              <Route path="/videos/details/:id" element={<VideoDetails />} />
              <Route path="/Library" element={<Librarylist />} />
              <Route path="/Wallet" element={<Walletlist />} />
              <Route path="/view_history" element={<RecentViewHistory />} />
              <Route path="/Test" element={<TradeModal />} />
            </Routes>
          </header>
        </Router>
      </UserProvider>
    </div>
  );
}

export default App;
