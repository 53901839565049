import { API_URL } from "../../globals";

export const getVideoSearchResults = async (searchTerm) => {
  try {
    const response = await fetch(`${API_URL}/videos/search/${searchTerm}`);
    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error searching for videos", err);
  }
};
