
import React, { useEffect, useState } from 'react';
import './WalletList.css';
import Video from './Video';
import { useUser } from './UserContext';
import TradeButton from './TradeButton';
import Searchbar from './Search/Searchbar';
const API_URL = process.env.REACT_APP_API_URL;

const Walletlist = () => {
    const [resources, setResources] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const currentUser = useUser();
    const [isTradeModalOpen, setIsTradeModalOpen] = useState(false);

    const [tradeModalAnchor, setTradeModalAnchor] = useState(null); // State to manage popover anchor
    const [tradeType, setTradeType] = useState(null); // Track trade type: 'buy' or 'sell'
    // Calculate the total number of owned resources
    const totalOwnedResources = resources.length;
    const totalPortfolioValue = resources.reduce((total, resource) => {
        return total + (resource.shares_owned * resource.share_price);
      }, 0);
      
    // Function to open the TradeModal
    const openTradeModal = (tradeType) => {
        setTradeType(tradeType);  // Set the trade type based on the button clicked
        setIsTradeModalOpen(true);
    };
    const handleTradeModalClose = () => {
        setTradeModalAnchor(null);
        setIsTradeModalOpen(false);
    };
    // const fetchResources = async (page) => {
    //     try {
    //         const response = await fetch(`${API_URL}/listAllOwnedResources?user_id=${currentUser.uid}&limit=5&offset=${(page - 1) * 10}`);
    //         if (!response.ok) {
    //             throw new Error(`Fetch error: ${response.statusText}`);
    //         }
    //         const data = await response.json();
    //         // console.log(data)
    //         const resourcesWithDetails = await Promise.all(
    //             data.map(async (obj) => {
    //               const resource_id = obj['resource_id'];
    //             //   console.log(resource_id);
    //               const resource_info = await fetchVideoData(resource_id);
    //             //   console.log(resource_info);
    //               return { ...obj, ...resource_info };
    //             })
    //           );
            
    //         setResources(resourcesWithDetails);
    //         console.log(resourcesWithDetails);
    //     } catch (error) {
    //         console.error('Error fetching resources:', error);
    //         // Handle error gracefully, e.g., display an error message on the UI
    //     }
    // };


    const fetchResources = async (page) => {
        try {
            const response = await fetch(`${API_URL}/listAllOwnedResources?user_id=${currentUser.uid}&limit=5&offset=${(page - 1) * 10}`);
            if (!response.ok) {
                throw new Error(`Fetch error: ${response.statusText}`);
            }
            const data = await response.json();
            // console.log(data)
            const resourcesWithDetails = await Promise.all(
                data.map(async (obj) => {
                  const resource_id = obj['resource_id'];
                //   console.log(resource_id);
                  const resource_info = await fetchVideoData(resource_id);
                //   console.log(resource_info);
                  return { ...obj, ...resource_info };
                })
              );

            setResources(resourcesWithDetails);
            console.log(resourcesWithDetails);
        } catch (error) {
            console.error('Error fetching resources:', error);
            // Handle error gracefully, e.g., display an error message on the UI
        }
    };

    const fetchVideoData = (async (resource_id) => {
        try {
          const response = await fetch(`${API_URL}/videos/${resource_id}`);
          const data = await response.json();
          return data;
        //   console.log(data)
        } catch (error) {
          console.error("Error fetching video data:", error);
        }
      });


    useEffect(() => {
        fetchResources(currentPage);
    }, [currentPage]);


    return (
        <div>
            <Searchbar></Searchbar>
            <div className='library-list-container'>
                
            <div className='portfolio-summary'>
                {/* <h2>Your Holdings</h2> */}
                <div className='metric'>
                <p className='metric-value'>{totalOwnedResources}</p>
                <p className='metric-label'>Total Owned Resources</p>
                </div>
                <div className='metric'>
                <p className='metric-value'>${totalPortfolioValue.toFixed(2)}</p>
                <p className='metric-label'>Total Portfolio Value</p>
                </div>
            </div>
                <div className='library-list'>
                    {resources.map(resource => (
                        <div key={resource.resource_id} className='library-list-item'>
                            <div className='library-list-video-details'>
                                <Video className='video-asset' id={resource.resource_id} />
                            </div>

                            <div className='library-list-ownership-details'>
                                <p>Shares Owned: {resource.shares_owned}</p>
                                <p>Current Price Per Share: ${resource.share_price}</p>
                                <p>Total Value: ${resource.shares_owned * resource.share_price}</p>
                                <div>
                                    <TradeButton id={resource.resource_id} orderType={'buy'}></TradeButton>
                                    <TradeButton id={resource.resource_id} orderType={'sell'}></TradeButton>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>

                <div className="pagination">
                    <button
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous Page
                    </button>
                    <button onClick={() => setCurrentPage(currentPage + 1)}>Next Page</button>
                </div>
            </div>
        </div>
    );
};

export default Walletlist;