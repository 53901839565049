import React, { useEffect, useState } from "react";
import Video from "./Video";
import { useUser } from "./UserContext";
import Searchbar from "./Search/Searchbar";
const API_URL = process.env.REACT_APP_API_URL;

const RecentViewHistory = () => {
  const [resources, setResources] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const currentUser = useUser();

  const fetchResources = async (page) => {
    try {
      const response = await fetch(
        `${API_URL}/listRecentViewHistory?user_id=${
          currentUser.uid
        }&limit=10&offset=${(page - 1) * 10}`
      );
      if (!response.ok) {
        throw new Error(`Fetch error: ${response.statusText}`);
      }
      const data = await response.json();
      setResources(data); // Update videos state with fetched data
    } catch (error) {
      console.error("Error fetching resources:", error);
      // Handle error gracefully, e.g., display an error message on the UI
    }
  };

  useEffect(() => {
    fetchResources(currentPage);
  }, [currentPage]);

  return (
    <div>
      <Searchbar></Searchbar>
      <div className="library-list-container">
        <p>Your Recent View History</p>
        <div className="library-list">
          {resources.map((resource) => (
            <div className="library-list-item">
              <div className="library-list-video-details">
                <Video
                  className="video-asset"
                  key={resource.video_id}
                  id={resource.video_id}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="pagination">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous Page
          </button>
          <button onClick={() => setCurrentPage(currentPage + 1)}>
            Next Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecentViewHistory;
