import { useState, useEffect } from "react";
import { API_URL } from "../../../globals";
export const useFetchCommentUserData = () => {
  const [commentUserData, setCommentUserData] = useState(null);

  useEffect(() => {
    const fetchCommentUserData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/user/CoEltAl9wkPHWU75du6LnXT5HWD3`
        );
        // const response = await fetch(`${API_URL}/getCommentUser/${commentId}`)
        // console.log(response);
        const data = await response.json();
        setCommentUserData(data);
      } catch (err) {
        console.error("Error fetching comment user data", err);
      }
    };

    fetchCommentUserData();
  }, []);

  return [commentUserData, setCommentUserData];
};
